import React from "react";
import BlogTeaser from "../components/blog-teaser";
import { useTheme, Theme, Container, Grid, Box, Typography, Hidden, Button } from "@material-ui/core";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Section from "../components/section";
import { ShapeArea, ShapeStack, ShapeDown } from "../components/shapes";
import styled from "@emotion/styled";
import ReactMarkdown from "react-markdown";
import ContentBlock from "../components/contentBlock";
import AutoPlaySlider from "../components/autoPlaySlider";
import { RoundedImg } from "../components/styledComponents";
import SEO from "../components/seo";
import MainLayout from "../layouts/mainLayout";

interface LocationProps {
    location: Location;
}

const InnerAppBox = styled(Box)((props: { theme: Theme }) => `
    padding: 48px;

    ${props.theme.breakpoints.down("sm")} {
        padding: 0px;
    }
`);

const AppImageContain = styled.div`
    margin: auto;
    max-width: 65%;
`;

const FatBar = styled.div((props: { theme: Theme }) => `
    height: 8px;
    width: 125px;
    margin-top: 8px;
    margin-right: 8px;
    border-radius: 16px;
    background-color: ${props.theme.palette.secondary.main}
`);

const ExtendedGutter = styled(Typography)`
    padding-bottom: 40px;
`;

const ImageContainer = styled(Container)`
    margin-top: -155px;
`

const HeaderCompensate = styled(Section)`
    margin-top: -64px;
    padding-top: 96px;
`;

const BrainXQPage: React.FC<LocationProps> = (props) => {
    const theme = useTheme<Theme>();
    const data = useStaticQuery(graphql`
        query {
            seo: brainXqJson(block: {eq: "seo" }) {
                title
                description
                image {
                    childImageSharp {
                        resize(width: 750, height: 300, cropFocus: ENTROPY) {
                            src
                            width
                            height
                        }
                    }
                }
            }
            hero: brainXqJson(block: {eq: "hero" }) {
                appTitle
                appContent
                webAppCTA
                webAppUrl
                nativeAppTitle
                showAppDownloads
                downloads {
                  link
                  alt
                  imgSrc
                  key
                }
                appTeaserImage {
                    childImageSharp {
                        fluid(maxHeight: 500) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            about : brainXqJson(block: {eq: "about" }) {
                title
                content
                logoImage {
                    childImageSharp {
                        fluid(maxWidth: 325, maxHeight: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            features : brainXqJson(block: {eq: "features" }) {
                title
                features {
                    title
                    description
                }
                desktopImages {
                    altText
                    source {
                        childImageSharp {
                            fluid(maxWidth: 900) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                mobileImages {
                    altText
                    source {
                        childImageSharp {
                            fluid(maxWidth: 550) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
            finalCta: brainXqJson(block: {eq: "finalCta"}) {
                title
                description
            }
        }
    `);

    return (
        <MainLayout>
            <SEO {...data.seo} location={props.location} />
            <HeaderCompensate bgcolor={theme.palette.primary.main}>
                <Container>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Box px={4} pb={4}>
                                <ShapeArea marginTop={0} marginBottom={0}>
                                    <ShapeStack top={25}>
                                        <ShapeDown color={theme.palette.primary.light} rotate={135} opacity={15} translateX={-50} translateY={150} />
                                        <ShapeDown color={theme.palette.secondary.main} rotate={-80} scale={1.05} opacity={80} translateX={-425} translateY={65} />
                                        <ShapeDown color={theme.palette.secondary.light} rotate={120} opacity={75} translateX={200} translateY={0} />
                                    </ShapeStack>
                                </ShapeArea>
                                <AppImageContain>
                                    <Img fluid={data.hero.appTeaserImage.childImageSharp.fluid} />
                                </AppImageContain>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InnerAppBox theme={theme} px={1} color={theme.palette.primary.contrastText}>
                                <Typography variant="h3" component="h1" gutterBottom={false}>{data.hero.appTitle}</Typography>
                                <ReactMarkdown source={data.hero.appContent} />
                                {data.hero.webAppCTA && <Hidden mdDown>
                                    <Box textAlign="center" pt={1} pb={2}>
                                        <Button variant="contained" size="large" color="secondary" href={data.hero.webAppUrl} target="__blank">{data.hero.webAppCTA}</Button>
                                    </Box>
                                </Hidden>
                                }
                                {data.hero.nativeAppTitle &&
                                    <Typography variant="h6" align="center">{data.hero.nativeAppTitle}</Typography>
                                }
                                {data.hero.showAppDownloads &&
                                    <Box pt={1} display="flex" alignItems="center" justifyContent="center">
                                        {data.hero.downloads.map((d, i) =>
                                            <a key={i} href={d.link} target="_blank" rel="noopener">
                                                <img height={d.key == "apple" ? 40 : 59} alt={d.alt} src={d.imgSrc} />
                                            </a>
                                        )}
                                    </Box>}
                            </InnerAppBox>
                        </Grid>
                    </Grid>
                </Container>
            </HeaderCompensate>

            {/* <Section>
                <Container maxWidth="md">
                    <ContentBlock {...data.about} />
                </Container>
            </Section> */}

            {/* <Section bgcolor={theme.palette.primary.light}>
                <Container>
                    <ExtendedGutter variant="h3" align="center">{data.features.title}</ExtendedGutter>
                    <Grid container spacing={4}>
                        {data.features.features.map((f, idx) =>
                            <Grid item sm={4} key={idx}>
                                <Box display="flex">
                                    <FatBar theme={theme} />
                                    <Box>
                                        <Typography variant="h4">{f.title}</Typography>
                                        <ReactMarkdown source={f.description} />
                                    </Box>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                    <Box height="105px" />
                </Container>
            </Section> */}

            {/* <Section tightTop>
                <ImageContainer maxWidth="md">
                    <Hidden xsDown>
                        <AutoPlaySlider>
                            {data.features.desktopImages.map((i, idx) =>
                                <Box key={idx} mb={4}>
                                    <RoundedImg fluid={i.source.childImageSharp.fluid} alt={i.altText} />
                                </Box>
                            )}
                        </AutoPlaySlider>
                    </Hidden>
                    <Hidden smUp>
                        <AutoPlaySlider>
                            {data.features.mobileImages.map((i, idx) =>
                                <Box key={idx} px={5} mb={4}>
                                    <RoundedImg fluid={i.source.childImageSharp.fluid} alt={i.altText} />
                                </Box>
                            )}
                        </AutoPlaySlider>
                    </Hidden>
                </ImageContainer>
            </Section> */}

            {/* <Section noBottom>
                <Container maxWidth="xs">
                    <ShapeArea marginTop={0} marginBottom={0}>
                        <ShapeStack top={-55} left={-35} zIndex={-1}>
                            <ShapeDown color={theme.palette.secondary.light} scale={0.98} rotate={31} opacity={35} />
                        </ShapeStack>
                    </ShapeArea>
                </Container>
                <Box px={2}>
                    <Typography variant="h3" align="center" gutterBottom={true}>{data.finalCta.title}</Typography>
                </Box>
                <Container maxWidth="sm">
                    <Box textAlign="center">
                        <ReactMarkdown source={data.finalCta.description} />
                    </Box>
                </Container>
                {data.hero.webAppCTA &&
                    <Box textAlign="center" py={2}>
                        <Button variant="contained" size="large" color="secondary" href={data.hero.webAppUrl} target="__blank">{data.hero.webAppCTA}</Button>
                    </Box>
                }
                {data.hero.nativeAppTitle &&
                    <Typography variant="h6" align="center">{data.hero.nativeAppTitle}</Typography>
                }
                {data.hero.showAppDownloads && 
                    <Box display="flex" alignItems="center" justifyContent="center">
                        {data.hero.downloads.map(d =>
                            <a key={d.key} href={d.link} target="_blank" rel="noopener">
                                <img height={d.key == "apple" ? 40 : 59} alt={d.alt} src={d.imgSrc} />
                            </a>
                        )}
                    </Box>
                }
            </Section> */}

            <BlogTeaser />
        </MainLayout>
    )
}

export default BrainXQPage